import { h, render, Fragment } from "preact";
import { useState } from "preact/hooks";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import useAuth from "js/components/User/useAuth.js";
import "css/pages/navbar.css";

export default function Header() {
    const auth = useAuth();
    const [navbar, setNavbar] = useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener("scroll", changeBackground);

    return (
        <Popover>
            <div
                className={
                    navbar ? "navbar active font-heebo" : "navbar font-heebo"
                }
            >
                <div className="max-w-7xl mx-auto px-4 mb-5 sm:px-6">
                    <div className="flex justify-between items-center md:justify-start md:space-x-10">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a className="w-[200px] h-[100px]" href="/">
                                {/* <span className="font-semibold text-logoblue-200">TODAY</span><span className="font-semibold text-lightorange-100">CASH</span> */}
                                <span className="sr-only">Today Cash</span>
                                <img
                                    className="w-auto pt-[30px]"
                                    src="/public/images/tc_logo_nobg.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button className="bg-lightorange-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-logoblue-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>
                        <Popover.Group
                            as="nav"
                            className="hidden md:flex md:space-x-5 lg:space-x-10"
                        >
                            <a
                                href="/rates-and-terms"
                                className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                            >
                                Rates & Terms
                            </a>
                            <a
                                href="/faq"
                                className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                            >
                                FAQ
                            </a>
                            <a
                                href="/contact-us"
                                className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                            >
                                Contact
                            </a>
                        </Popover.Group>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            {(auth.loading || !auth.user) && (
                                <Fragment>
                                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                                        <a
                                            href="/login"
                                            className="whitespace-nowrap text-base font-inter font-medium text-lightorange-100 hover:text-logoblue-200"
                                        >
                                            {" "}
                                            Sign in{" "}
                                        </a>
                                        <a
                                            href="/apply-now"
                                            className="ml-8 whitespace-nowrap inline-flex items-center justify-center font-inter px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white transition ease-in-out delay-120 bg-lightorange-100 hover:bg-white duration-300 hover:text-lightorange-100 hover:border-lightorange-100"
                                        >
                                            {" "}
                                            Apply Now{" "}
                                        </a>
                                    </div>
                                </Fragment>
                            )}
                            {!auth.loading && auth.user && (
                                <Fragment>
                                    <a
                                        href="/account"
                                        className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 mr-2"
                                    >
                                        My Account
                                    </a>
                                    <span className="text-logoblue-200">|</span>
                                    <a
                                        onClick={() => {
                                            auth.sign_out(() => {
                                                window.location = "/";
                                            });
                                        }}
                                        className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 ml-2"
                                    >
                                        Logout
                                    </a>
                                </Fragment>
                            )}
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 bg-white divide-y-2 divide-logoblue-200">
                                <div className="pt-5 pb-6 px-5">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <a href="/">
                                                <div className="flex items-center">
                                                    <img
                                                        className="w-6/12"
                                                        src="/public/images/tc_logo_nobg.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-lightorange-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-logoblue-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">
                                                    Close menu
                                                </span>
                                                <XIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-6 px-5">
                                    <div className="flex flex-col p-4">
                                        <a
                                            href="/rates-and-terms"
                                            className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                        >
                                            Rates & Terms
                                        </a>
                                        <a
                                            href="/faq"
                                            className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                        >
                                            FAQ
                                        </a>
                                        <a
                                            href="/contact-us"
                                            className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                        >
                                            Contact
                                        </a>
                                        {(auth.loading || !auth.user) && (
                                            <Fragment>
                                                <a
                                                    href="/login"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                                >
                                                    Sign in
                                                </a>
                                                <a
                                                    href="/apply-now"
                                                    className="whitespace-nowrap text-base font-medium text-logoblue-200"
                                                >
                                                    Apply Now
                                                </a>
                                            </Fragment>
                                        )}
                                        {!auth.loading && auth.user && (
                                            <Fragment>
                                                <a
                                                    href="/account"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 mr-2"
                                                >
                                                    My Account
                                                </a>
                                                |
                                                <a
                                                    onClick={() => {
                                                        auth.sign_out(() => {
                                                            window.location =
                                                                "/";
                                                        });
                                                    }}
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 ml-2"
                                                >
                                                    Logout
                                                </a>
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* <div className="mt-6 flex justify-evenly">
                                        {(auth.loading || !auth.user) && (
                                            <Fragment>
                                                <a
                                                    href="/login"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                                >
                                                    Sign in
                                                </a>
                                                <a
                                                    href="/apply-now"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                                >
                                                    Apply Now
                                                </a>
                                            </Fragment>
                                        )}
                                        {!auth.loading && auth.user && (
                                            <Fragment>
                                                <a
                                                    href="/account"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 mr-2"
                                                >
                                                    My Account
                                                </a>
                                                |
                                                <a
                                                    onClick={() => {
                                                        auth.sign_out(() => {
                                                            window.location =
                                                                "/";
                                                        });
                                                    }}
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 ml-2"
                                                >
                                                    Logout
                                                </a>
                                            </Fragment>
                                        )}
                                    </div> */}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </div>
            </div>
        </Popover>
    );
}
